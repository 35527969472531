import '../styles/previous-rolls.css';

export const PreviousRolls = ({ rolls, count }) => {
  const slicedRolls = rolls.slice((count * -1)).reverse();
  const lastIndex = slicedRolls.length - 1;
  const rollElements = slicedRolls.map((currentValue, currentIndex) => {
    const isLastElement = currentIndex === lastIndex;
    return <div className={!isLastElement ? 'roll' : 'lastRoll' }>
      &nbsp;{currentValue}
    </div>
  });
  return (
    <div className="prevRolls">
      <strong>Last {count} Rolls:</strong> {rollElements}
    </div>
  );
};
