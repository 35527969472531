import '../styles/pieces.css'

export const Pieces = ({ players, setSelectedColor, selectedColor }) => {
  return (
    <div className="piecesContainer">
      {Object.keys(players).map((color) => {
        const colorClass = color.toLowerCase();
        const activeClass = selectedColor === color ? ' active' : '';
        return (
          <div
            className={`piece ${colorClass}${activeClass}`}
            onClick={() => { setSelectedColor(color) }}
          >
            &nbsp;
          </div>
        );
      })}
    </div>
  )
};
