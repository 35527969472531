import { useState } from 'react';

import { RollPercentages } from './RollPercentages';
import { Pieces } from './Pieces';

import '../styles/players.css';

const PlayersList = ({ players, playerRolls, orderedColors }) => {
  return (
    <div className="playerRolls">
      {orderedColors.map((color) => {
        if (!players[color].name) {
          return null;
        }
        return (
          <div>
            <RollPercentages title={`${players[color].name} [${color.toLowerCase()}]`} rolls={playerRolls[color]} />
          </div>
        );
      })}
    </div>
  );
};

export const Players = ({
  players,
  setPlayers,
  selectedColor,
  playerRolls,
  setSelectedColor,
  orderedColors,
}) => {
  const [currentPlayerName, setCurrentPlayerName] = useState('');


  const handleAddPlayerClick = () => {
    if (currentPlayerName === '') {
      return;
    }
    const nextAvailablePlace = Object.keys(players).filter((color) => {
      if (players[color] && players[color].name !== null) {
        return true;
      }
    }).length;
    const newPlayers = {
      ...players,
      [selectedColor]: {
        name: currentPlayerName,
        place: nextAvailablePlace,
      }

    };
    setPlayers(newPlayers);
    setCurrentPlayerName('');
    localStorage.setItem('players', JSON.stringify(newPlayers));
  };

  const handleResetPlayersClick = () => {
    const newPlayers = {};
    Object.keys(players).forEach((color) => {
      newPlayers[color] = {
        name: null,
        place: null,
      };
    })
    localStorage.setItem('players', JSON.stringify(newPlayers));
    setPlayers(newPlayers);
  }

  const handlePlayerNameChange = ({ target }) => {
    setCurrentPlayerName(target.value);
  };
  return (<>
    <PlayersList
        players={players}
        setPlayers={setPlayers}
        playerRolls={playerRolls}
        orderedColors={orderedColors}
      />
      <center>
        <Pieces
          setSelectedColor={setSelectedColor}
          selectedColor={selectedColor}
          players={players}
        />
        <input
          placeholder="Player's name"
          className="playerNameInput"
          type="text"
          id="player_name"
          onChange={handlePlayerNameChange}
          value={currentPlayerName}
        />
        <button className="addPlayerButton" onClick={handleAddPlayerClick}>
          Add Player
        </button>
        <button className="resetPlayersButton" onClick={handleResetPlayersClick}>
          Reset Players
        </button>
    </center>
  </>);
};
