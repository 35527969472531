import { SetDiceButton } from './SetDiceButton';

import '../styles/dice.css';

const DiceImages = ({ setDice, getDice }) => {

  const currentDice = getDice();

  return (
    <div className="diceImages">
      <div className={`dice first-face${currentDice === 1 ? ' active' : ''}`} onClick={() => setDice(1)}>
        <span className="dot"></span>
      </div>
      <div className={`dice second-face${currentDice === 2 ? ' active' : ''}`} onClick={() => setDice(2)}>
        <span className="dot"></span>
        <span className="dot"></span>
      </div>
      <div className={`dice third-face${currentDice === 3 ? ' active' : ''}`} onClick={() => setDice(3)}>
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
      </div>
      <div className={`dice fourth-face${currentDice === 4 ? ' active' : ''}`} onClick={() => setDice(4)}>
        <div className="column">
          <span className="dot"></span>
          <span className="dot"></span>
        </div>
        <div className="column">
          <span className="dot"></span>
          <span className="dot"></span>
        </div>
      </div>
      <div className={`dice fifth-face${currentDice === 5 ? ' active' : ''}`} onClick={() => setDice(5)}>
        <div className="column">
          <span className="dot"></span>
          <span className="dot"></span>
        </div>
        <div className="column">
          <span className="dot"></span>
        </div>
        <div className="column">
          <span className="dot"></span>
          <span className="dot"></span>
        </div>
      </div>
      <div className={`dice sixth-face${currentDice === 6 ? ' active' : ''}`} onClick={() => setDice(6)}>
      <div className="column">
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
      </div>
      <div className="column">
        <span className="dot"></span>
        <span className="dot"></span>
            <span className="dot"></span>
      </div>
    </div>
  </div>
  );
};

export const Dice = ({
  setDiceRolls,
  setDiceValues,
  diceRolls,
  diceValues,
  setPlayerRolls,
  playerRolls,
  players,
  setCurrentPlace,
  currentPlace,
  numOfPlaces,
  setSelectedColor,
}) => {
  const activePlayers = Object.keys(players).filter(color => {
    console.log('color.place:', color.place);
    if (players[color].place !== null) {
      return true;
    }
  });
  console.log('currentPlace:', currentPlace);
  const setDiceValue = (diceNum, diceValue) => {
    setDiceValues({
      ...diceValues,
      [diceNum]: diceValue
    });
  };
  const getDice = (diceNum) => {
    return () => {
      return diceValues[diceNum]
    };
  };
  const setDice = (diceNum) => {
    return (diceValue) => {
      setDiceValue(diceNum, diceValue);
    };
  };
  const getCurrentColor = () => {
    console.log('========+===============+=============')
    console.log('getCurrentColor:')
    console.log('players:', players);
    console.log('currentPlace:', currentPlace)
    return Object.keys(players).filter(color => {
      if (players[color].place === currentPlace) {
        return true;
      }
    })[0]
  }

  const getLastColor = () => {
    let previousPlace

    console.log('activePlayers:', activePlayers)
    if (currentPlace === 0) {
      previousPlace = activePlayers.length - 1;
      console.log('previousPlace from 0:', previousPlace)
    } else {
      previousPlace = currentPlace - 1
    }
    return Object.keys(players).filter(color => {
      if (players[color].place === previousPlace) {
        return true
      }
    })[0]
  };

  const setNextPlace = () => {
    const nextPlace = currentPlace + 1 === numOfPlaces
      ? 0
      : currentPlace + 1;
    localStorage.setItem('current-place', nextPlace);
    setCurrentPlace(nextPlace);
  }
  const selectedColor =  getCurrentColor();
  const setPlayerRoll = (value) => {
    const newPlayerRolls = { ...playerRolls };
    console.log('currenPlace:', currentPlace);
    console.log('selectColor during setPlayerRoll:', selectedColor)
    newPlayerRolls[selectedColor].push(value);
    const currentPlayerRolls = JSON.parse(localStorage.getItem('player-rolls') || '{}');
    console.log('currentPlayerRolls:', currentPlayerRolls);
    console.log('=====================================');
    currentPlayerRolls[selectedColor] = currentPlayerRolls[selectedColor] || [];
    currentPlayerRolls[selectedColor].push(value);
    localStorage.setItem('player-rolls', JSON.stringify(currentPlayerRolls));
    setNextPlace();
    setPlayerRolls(newPlayerRolls);
  }
  const setLastDiceRoll = (value) => {
    const newDice = [
      ...diceRolls,
      value
    ];
    setPlayerRoll(value);
    setDiceRolls(newDice);
    localStorage.setItem('dice', JSON.stringify(newDice));
  };
  const handleSetDiceButtonClick = () => {
    if (!diceValues[1] || !diceValues[2]) {
      return;
    }
    const lastRoll = diceValues[1] + diceValues[2];
    setLastDiceRoll(lastRoll);
    setDiceValues({ 1: null, 2: null });
  };
  const handleRemoveDiceClick = () => {
    const lastColor = getLastColor();
    console.log('lastColor:', lastColor);
    const diceRollsWithoutLastItem = diceRolls.slice(0, -1);
    const playerRollsWithoutLastItem = playerRolls[lastColor].slice(0, -1);
    const newPlayerRolls = {
      ...playerRolls,
      [lastColor]: playerRollsWithoutLastItem
    };
    setPlayerRolls(newPlayerRolls);
    console.log('new player rolls:', newPlayerRolls)
    localStorage.setItem('player-rolls', JSON.stringify(newPlayerRolls));
    setDiceRolls(diceRollsWithoutLastItem)
    localStorage.setItem('dice', JSON.stringify(diceRollsWithoutLastItem));
    const previousPlace = currentPlace - 1 === -1
      ? numOfPlaces - 1
      : currentPlace - 1;
    localStorage.setItem('current-place', previousPlace);
    setCurrentPlace(previousPlace);
    setSelectedColor(lastColor)
  };
  return (
    <>
      <DiceImages setDice={setDice(1)} getDice={getDice(1)} />
      <br />
      <DiceImages setDice={setDice(2)} getDice={getDice(2)} />
      <br />
      <br />
      <SetDiceButton handleSetDiceClick={handleSetDiceButtonClick} handleRemoveDiceClick={handleRemoveDiceClick} />
    </>
  )
}
