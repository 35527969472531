import { useState } from 'react';
import { Dice } from './components/Dice';
import { RollPercentages } from './components/RollPercentages';
import { Players } from './components/Players';
import { PreviousRolls } from './components/PreviousRolls';

const App = () => {

  const savedDiceRolls = localStorage.getItem('dice');
  let initialDiceState = [];
  if (savedDiceRolls) {
    initialDiceState = JSON.parse(savedDiceRolls);
  }

  const allColors = ['RED', 'BLUE', 'WHITE', 'BROWN', 'ORANGE', 'GREEN'];
  const savedPlayers = localStorage.getItem('players');
  let initialPlayerState = {}
  allColors.forEach((color) => {
    initialPlayerState[color] = {
      name: null,
      place: null,
    };
  });
  if (savedPlayers) {
    initialPlayerState = JSON.parse(savedPlayers);
  }

  const playerRollsInitialState = {}
  allColors.forEach((color) => {
    playerRollsInitialState[color] = [];
  })

  let currentPlaceInitialState = 0;
  const savedCurrentPlace = localStorage.getItem('current-place');
  if (
    savedCurrentPlace &&
    !isNaN(Number(savedCurrentPlace))
  ) {
    currentPlaceInitialState = Number(savedCurrentPlace);
  }

  const [diceValues, setDiceValues ] = useState({ 1: null, 2: null });
  const [diceRolls, setDiceRolls] = useState(initialDiceState);
  const [playerRolls, setPlayerRolls] = useState(playerRollsInitialState);
  const [players, setPlayers] = useState(initialPlayerState);
  const [selectedColor, setSelectedColor] = useState('RED');
  const [currentPlace, setCurrentPlace] = useState(0);

  const orderedColors = Object.keys(players).sort((firstColor, secondColor) => {
    if ((players[firstColor] && players[firstColor].place) < (players[secondColor] && players[secondColor].place)) {
      return -1;
    } else {
      return 1;
    }
  }).filter((color) => {
    if (players[color].name !== null) {
      return true;
    }
  });

  return (
    <div className="App">
      <Dice
        setDiceRolls={setDiceRolls}
        setDiceValues={setDiceValues}
        diceRolls={diceRolls}
        diceValues={diceValues}
        selectedColor={selectedColor}
        setPlayerRolls={setPlayerRolls}
        playerRolls={playerRolls}
        players={players}
        currentPlace={currentPlace}
        setCurrentPlace={setCurrentPlace}
        numOfPlaces={orderedColors.length}
        setSelectedColor={setSelectedColor}
      />
      <br />
      <br />
      <PreviousRolls rolls={diceRolls} count={15} />
      <br />
      <br />
      <RollPercentages rolls={diceRolls} title="all" />
      <br />
      <br />
      <Players
        players={players}
        setPlayers={setPlayers}
        selectedColor={selectedColor}
        playerRolls={playerRolls}
        setSelectedColor={setSelectedColor}
        orderedColors={orderedColors}
      />
    </div>
  );
};

export default App;
