import '../styles/roll-percentages.css';

export const RollPercentages = ({ rolls, title }) => {

  let totalRolls = 0;
  const rollCount = {
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0
  };
  rolls.forEach((rollValue) => {
    totalRolls += 1;
    rollCount[rollValue] += 1;
  });
  const allPossibleValues = Object.keys(rollCount);
  const percentageRows = allPossibleValues.map((currentValue) => {
    const currentPercentage = (rollCount[currentValue] / totalRolls).toFixed(2) * 100
    return (
      <td>
        {isNaN(currentPercentage) ? 0 : currentPercentage.toFixed(0)}%
      </td>
    );
  });
  const countRows = allPossibleValues.map((currentValue) => {
    const currentCount = rollCount[currentValue]
    return (
      <td>
        {currentCount}
      </td>
    );
  });
  return (
    <center>
      <h1>{title}</h1>
      <table className="percentagesTable">
        <thead>
          <tr>
            <td>2</td>
            <td>3</td>
            <td>4</td>
            <td>5</td>
            <td>6</td>
            <td>7</td>
            <td>8</td>
            <td>9</td>
            <td>10</td>
            <td>11</td>
            <td>12</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            {percentageRows}
          </tr>
          <tr>
            {countRows}
          </tr>
        </tbody>
      </table>
    </center>
  );
};
